<template>
   <div  class="winrate-progress__wrapper"> 
      <template v-if="isShowWinRate">
      <div class="winrate-progress-bar">
        <div class="winrate-win" :style="`width:${winRate.winRate}%`"></div>
        <div class="winrate-draw" :style="`width:${winRate.drawRate}%`"></div>
        <div class="winrate-lose" :style="`width:${winRate.loseRate}%`"></div>
      </div>
      <div class="winrate-block__wrapper">
        <div class="winrate-block__container">
          <div class="winrate-block">{{ $t('WINRATE_H') }}</div>
          <div>
            <div class="winrate-label">{{ $t('WINS') }}</div>
            <div class="winrate-value">{{winRate.winRate}}%</div>
          </div>
        </div>
        <div class="winrate-block__container">
          <div class="winrate-block winrate-block-draw">{{ $t('WINRATE_D') }}</div>
          <div>
            <div class="winrate-label">{{ $t('DRAWS') }}</div>
            <div class="winrate-value">{{winRate.drawRate}}%</div>
          </div>
        </div>
        <div class="winrate-block__container">
          <div class="winrate-block winrate-block-lose">{{ $t('WINRATE_A') }}</div>
          <div>
            <div class="winrate-label">{{ $t('WINS') }}</div>
            <div class="winrate-value">{{winRate.loseRate}}%</div>
          </div>
        </div>
      </div>
      </template>
      
        <div class="mobile-odds" v-show="curCountryObj.countryCode === 'KH' && isMobile && isShowDetailOdds">
            <swiper>
              <swiper-slide >
                <div class="mobile-odds__img-wrapper">
                  <img id="mobile-odds-img" class="mobile-odds__img" src="../../../static/images/icons/icon_play168.png">
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="odds-block__wrapper">
                  <a :href="play168Url" class="odds-block__container">
                    <div class="odds-block">
                      <div>1</div>
                      <div>{{detailOdds.homeOdds}}</div>
                    </div>
                    <div class="odds-block__img-wrapper">
                      <img src="../../../static/images/icons/icon_play168.png">
                    </div>
                  </a>
                  <a :href="play168Url" class="odds-block__container">
                    <div class="odds-block">
                      <div>x</div>
                      <div>{{detailOdds.drawOdds}}</div>
                    </div>
                    <div class="odds-block__img-wrapper">
                      <img src="../../../static/images/icons/icon_play168.png">
                    </div>
                  </a>
                  <a :href="play168Url" class="odds-block__container">
                    <div class="odds-block">
                      <div>2</div>
                      <div>{{detailOdds.awayOdds}}</div>
                    </div>
                    <div class="odds-block__img-wrapper">
                      <img src="../../../static/images/icons/icon_play168.png">
                    </div>
                  </a>
                </div>
              </swiper-slide>
            </swiper>
        </div>
      
        <div v-show="curCountryObj.countryCode === 'KH' && !isMobile && isShowDetailOdds" class="odds-block__wrapper">
          <a :href="play168Url" class="odds-block__container">
            <div class="odds-block">
              <div>1</div>
              <div>{{detailOdds.homeOdds}}</div>
            </div>
            <div class="odds-block__img-wrapper">
              <img src="../../../static/images/icons/icon_play168.png">
            </div>
          </a>
          <a :href="play168Url" class="odds-block__container">
            <div class="odds-block">
              <div>x</div>
              <div>{{detailOdds.drawOdds}}</div>
            </div>
            <div class="odds-block__img-wrapper">
              <img src="../../../static/images/icons/icon_play168.png">
            </div>
          </a>
          <a :href="play168Url" class="odds-block__container">
            <div class="odds-block">
              <div>2</div>
              <div>{{detailOdds.awayOdds}}</div>
            </div>
            <div class="odds-block__img-wrapper">
              <img src="../../../static/images/icons/icon_play168.png">
            </div>
          </a>
        </div>
   </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "@/js/config.js";

import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
      return {
          isShowWinRate: false,
          isShowDetailOdds: false,
          play168Url: config.play168Url,
          curCountryObj: {},
      }
  },
  props: {
      winRate: {
          default: {},
          type: Object
      },
      detailOdds:{
          default: {},
          type: Object
      }
  },
  watch: {
      winRate: {
          deep: true,
          immediate: true,
          handler(newVal, oldVal) {
              if (newVal !== null && Object.keys(newVal).length > 0) {
                  this.isShowWinRate = true;
              }
          },
      },
      detailOdds: {
          deep: true,
          handler(newVal, oldVal) {
              if (newVal) {
                    setTimeout(() => {
                      document.getElementById("mobile-odds-img").style.opacity = "1";
                      setTimeout(() => {
                        const swiper = document.querySelector('.swiper-container').swiper;
                        swiper.slideNext();
                        document.getElementById("mobile-odds-img").style.opacity = "0";
                      },1000)
                 
                    }, 1000);
              }
          },
      },
      currentCountryObj: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal) {
            this.curCountryObj = {...newVal};
          }
        },
      },
      manualRefreshMatchDetailScore: {
        handler(newVal, oldVal) {
          if (newVal) {
            const swiper = document.querySelector('.swiper-container').swiper;
            swiper.slidePrev();
            setTimeout(() => {
                document.getElementById("mobile-odds-img").style.opacity = "1";
            }, 1000);
          }
        }
      },
  },
  mounted() {
    if (this.$route.meta.category !== "highlightsDetail") {
      this.isShowDetailOdds = true;
    }
  },
  computed: {
    ...mapGetters(["currentCountryObj", "isMobile", "manualRefreshMatchDetailScore"]),
  },
}
</script>

<style>
.winrate-progress__wrapper{
  margin: 16px 0;
  width: 720px;
}
.winrate-progress-bar{
  height: 8px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  background: #6F8C9B;
  margin-bottom: 16px;
}
.winrate-win{
  width: 33.3%;
  height: inherit;
  background: #2E8FEF;
}
.winrate-draw{
  width: 33.3%;
  height: inherit;
  background: #CFD9DE;
}
.winrate-lose{
  width: 33.3%;
  background: #6F8C9B;
  height: inherit;
}
.winrate-block__wrapper{
  display: flex;
  justify-content: space-between;
}
.winrate-block__container{
  display: flex;
  align-items: center;
}

.winrate-block{
  width: 48px;
  height: 40px;
  border-radius: 8px;
  background: #2E8FEF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-weight: 700;
}
.winrate-block-draw{
  background: #CFD9DE;
  color:black;
}
.winrate-block-lose{
  background: #6F8C9B;
}
.winrate-label{
  font-weight: 700;
  font-size: 14px;
}
.winrate-value{
  font-weight: 700;
  font-size: 11px;
}

.odds-block__container{
  background: #35454D;
  border-radius: 8px;
  width: 112px;
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 700;
  position: relative;
  overflow: hidden;
  height: 36px;
}
.odds-block__wrapper{
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

}
.odds-block__img-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  opacity: 0;
  transition: all .3s;
  border-radius: 8px;
}
.odds-block{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  padding: 8px 10px;

}

.odds-block__container:hover .odds-block__img-wrapper{
  opacity: 1;
  background: #35454D;
  border: 2px solid #01C3F7;
}
@media (max-width: 768px) {
    .winrate-progress__wrapper{
      width: auto;
    }
    .winrate-block{
      width: 32px;
      height: 24px;
      font-size: 11px;
      border-radius: 4px;
      margin-right: 8px;
    }
    .winrate-progress-bar{
      width: 100%;
      height: 4px;
      margin-bottom: 4px;
    }
    .winrate-label{
      font-size: 11px;
    }
    .winrate-value{
      font-size: 9px;
    }
    .winrate-progress__wrapper {
      margin: 12px 21px;
    }

    .odds-block__container{
      width: 72px;
      height: 28px;
      font-size: 11px;
    }
    .odds-block__wrapper{
      width: 100%;
      transition: all .3s;
      margin-top: 0;
      /* transform: translateX(100vw);
      display: none; */
    }
    .mobile-odds{
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s;
      margin-top: 15px;
    }
    .mobile-odds__img-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .mobile-odds__img{
      opacity: 0;
      transition: all .3s;
    }
    .odds-block__container:hover .odds-block__img-wrapper{
      opacity: 0;
    }
}
</style>