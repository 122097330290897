<template>
  <!-- live stream may be available before match starts or continue after match ends due to delay -->
  <!-- tracker may be available before match starts or continue after match ends due to delay -->
  <MobileHeader
    v-if="matchDetailScore.isFavMatch !== undefined"
    :mobileHeaderTitle="mobileHeaderTitle"
    :matchState="matchDetailScore.matchState"
    :isMatchEndedOrCancelled="isMatchEndedOrCancelled"
    :containLiveStream="matchDetailScore.containLiveStream"
    :containTracker="isShowLiveTrackerUrl"
    :isShowHighlightButton="matchDetailScore.containHighlight && this.matchEnded"
    :isShowFavButton="false"
    :isShowSocialMediaShareButton="true"
    :isShowRefreshButton="true"
    :isFav="isFavMatch"
    :favDisabled="matchEnded && isLogin"
    :isTrackerSelected="isTrackerSelected"
    @toggleLiveStream="toggleLiveStream"
    @toggleTracker="toggleTracker"
    @toggleFavThis="toggleFavMatch($event)"
    @handleHighlight="handleHighlight"
    @moreEvent="handleMore()"
  ></MobileHeader>

  <div class="home has-header">
    <div class="container">
      <ScoreInfo :data="matchDetailScore" :winRate="matchDetailWinRate" :detailOdds="matchDetailOdds" v-if="!isMobile"> </ScoreInfo>
      <!-- mt-m  -->
      <div
        class="mb-l display-flex-center"
        v-if="!isMobile"
      >
        <router-link :to="highlightDetailLink" target="_blank" v-if="matchDetailScore.containHighlight && this.matchEnded">
          <div class="nav-pill display-flex-center">
            <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" class=" match-detail__icon">
              <path d="M14.9432 0.484863H2.05682C1.26216 0.484863 0.625 1.12202 0.625 1.91668V10.5076C0.625 11.2951 1.26216 11.9394 2.05682 11.9394H5.63636V13.3712H11.3636V11.9394H14.9432C15.7307 11.9394 16.3678 11.2951 16.3678 10.5076L16.375 1.91668C16.375 1.12202 15.7307 0.484863 14.9432 0.484863ZM14.9432 10.5076H2.05682V1.91668H14.9432V10.5076ZM11.3636 6.21214L6.35227 9.07577V3.3485L11.3636 6.21214Z" fill="inherit"/>
            </svg>
            <!-- <svg
              width="17"
              height="13"
              viewBox="0 0 17 13"
              class=" match-detail__icon"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.05682 0.0250244H14.9432C15.7307 0.0250244 16.375 0.662183 16.375 1.45684L16.3678 10.0477C16.3678 10.8352 15.7307 11.4796 14.9432 11.4796H11.3636V12.9114H5.63636V11.4796H2.05682C1.26216 11.4796 0.625 10.8352 0.625 10.0477V1.45684C0.625 0.662183 1.26216 0.0250244 2.05682 0.0250244ZM11.3636 5.7523L6.35227 8.61594V2.88867L11.3636 5.7523Z"
                fill="inherit"
              />
            </svg> -->
            <!-- <span> {{ $t("HIGHLIGHTS") }}</span> -->
          </div>
        </router-link>
        
        <!-- v-show="liveSteamVideo.isShowVideo" -->
        <!-- live stream may be available before match starts or continue after match ends due to delay -->
        <div
          class="nav-pill display-flex-center  no-right-margin"
          :class="navPillLiveStreamStateClass"
          v-show="matchDetailScore.matchState > 0 || (matchDetailScore.containLiveStream && matchDetailScore.matchState == -1) || (matchDetailScore.containLiveStream && !isMatchEndedOrCancelled)"
          @click="toggleLiveStream"
        >
          <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.27273 1.75H16.7273C17.4273 1.75 18 2.31243 18 3.01389L17.9937 10.5147C18.9833 10.6349 19.75 11.4779 19.75 12.5V17.25C19.75 18.3546 18.8546 19.25 17.75 19.25H4.25C3.14543 19.25 2.25 18.3546 2.25 17.25V12.5C2.25 11.4801 3.01342 10.6385 4 10.5155V3.01389C4 2.31243 4.56636 1.75 5.27273 1.75ZM13.5455 6.80556L9.09091 9.33334V4.27778L13.5455 6.80556ZM4.4375 12.25V17.0352H7.39308V16.1973H5.45215V12.25H4.4375ZM8.22771 12.25V17.0352H9.24236V12.25H8.22771ZM13.1766 12.25H14.2011L12.5743 17.0352H11.468L9.84461 12.25H10.8691L11.7692 15.0976C11.8194 15.2656 11.8706 15.462 11.923 15.6867C11.9776 15.9093 12.0114 16.0642 12.0245 16.1515C12.0485 15.9507 12.1303 15.5994 12.2699 15.0976L13.1766 12.25ZM14.8066 17.0352H17.5625V16.1973H15.8212V14.9634H17.4414V14.132H15.8212V13.0814H17.5625V12.25H14.8066V17.0352Z" fill="none"/>
          </svg>
        </div>

        <!-- :class="{ active: highlightDesktopTrackerButton }" -->
        <!-- tracker may be available before match starts or continue after match ends due to delay -->
        <!-- v-show="matchDetailScore.matchState > 0 || (isShowLiveTrackerUrl && matchDetailScore.matchState == -1) || (isShowLiveTrackerUrl && !isMatchEndedOrCancelled)" -->
        <div
          class="nav-pill display-flex-center"
          :class="navPillTrackerStateClass"
          @mouseover="mouseoverTrackerButton"
          @mouseout="mouseoutTrackerButton"
          @click="toggleTracker"
          v-show="false"
        >
          <svg width="22" height="21" v-show="!isShowLiveTrackerUrl" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.98525 5.18018C8.09366 5.18018 6.55469 6.71915 6.55469 8.61074C6.55469 10.5023 8.09366 12.0413 9.98525 12.0413C11.8769 12.0413 13.4158 10.5023 13.4158 8.61074C13.416 6.71896 11.877 5.18018 9.98525 5.18018ZM9.98525 10.6689C8.85046 10.6689 7.9269 9.7453 7.9269 8.61056C7.9269 7.47577 8.85051 6.5522 9.98525 6.5522C11.12 6.5522 12.0436 7.47581 12.0436 8.61056C12.0436 9.74535 11.1202 10.6689 9.98525 10.6689Z" fill="none"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.125 8.61058C3.125 4.82724 6.20224 1.75 9.98558 1.75H9.98538C13.7689 1.75 16.8469 4.82724 16.8469 8.61058C16.8469 9.27752 16.7404 9.9407 16.5549 10.5901C16.1521 10.3854 15.7118 10.2439 15.2471 10.1786C15.3921 9.65835 15.4743 9.13304 15.4743 8.61058C15.4743 5.58419 13.0121 3.1223 9.98558 3.1223C6.95919 3.1223 4.4973 5.58405 4.4973 8.61058C4.4973 12.3237 8.63386 16.1682 9.98558 17.3256C10.2417 17.106 10.5978 16.7898 11.0068 16.3962C11.2364 16.8011 11.5313 17.1641 11.8773 17.4708C11.0787 18.2301 10.4845 18.6973 10.4068 18.7577C10.2833 18.8544 10.1344 18.9024 9.98557 18.9024C9.8367 18.9024 9.68784 18.8544 9.56415 18.7577C9.30128 18.5533 3.125 13.6904 3.125 8.61058Z" fill="none"/>
            <path d="M14.658 11.186C13.8238 11.186 13.0238 11.5175 12.434 12.1073C11.8441 12.6972 11.5127 13.4972 11.5127 14.3313C11.5127 15.1655 11.8441 15.9655 12.434 16.5554C13.0238 17.1453 13.8239 17.4767 14.658 17.4767C15.4922 17.4767 16.2922 17.1453 16.8821 16.5554C17.4719 15.9656 17.8033 15.1656 17.8033 14.3313C17.8033 13.7792 17.658 13.2368 17.3819 12.7587C17.1059 12.2806 16.7088 11.8835 16.2307 11.6074C15.7526 11.3314 15.2101 11.186 14.658 11.186ZM15.961 15.7422L15.5566 15.1896L15.8914 14.1673L16.5384 13.9562L17.354 14.538C17.3211 14.9647 17.1871 15.3775 16.9631 15.7422H15.961ZM13.3549 15.7422H12.3642C12.1401 15.3775 12.0061 14.9647 11.9732 14.538L12.7753 13.9561L13.4223 14.1673L13.7594 15.1896L13.3549 15.7422ZM12.4698 13.6213L11.989 13.9718C12.032 13.6495 12.1324 13.3375 12.2855 13.0507L12.4698 13.6213ZM14.1682 15.0053L13.8649 14.0729L14.6579 13.4977L15.451 14.0729L15.1478 15.0053H14.1682ZM16.8462 13.6213L17.0305 13.0507H17.0304C17.1835 13.3375 17.284 13.6495 17.327 13.9718L16.8462 13.6213ZM16.7092 12.5856L16.4036 13.5269L15.7543 13.7381L14.8826 13.1068V12.4328L15.6846 11.8487C16.0796 12.0098 16.4307 12.2624 16.7092 12.5857L16.7092 12.5856ZM15.1365 11.6869L14.6579 12.0307L14.1749 11.6802C14.3344 11.6516 14.496 11.6366 14.6579 11.6353C14.8199 11.6366 14.9815 11.6516 15.141 11.6802L15.1365 11.6869ZM13.6267 11.8464L14.4333 12.4239V13.0979L13.5616 13.7292L12.9123 13.518L12.6067 12.5857C12.8841 12.2591 13.2353 12.0034 13.6312 11.8397L13.6267 11.8464ZM12.7123 16.1915H13.3099L13.4942 16.7576V16.7576C13.2016 16.6175 12.9367 16.4257 12.7123 16.1915V16.1915ZM14.0244 16.9486L13.7188 16.0073L14.1188 15.4546H15.1972L15.5971 16.0073L15.2915 16.9486H15.2915C14.8756 17.0534 14.4402 17.0534 14.0244 16.9486H14.0244ZM15.8217 16.7576L16.0059 16.1915H16.599C16.3772 16.4248 16.1155 16.6166 15.8261 16.7576H15.8217Z" fill="none"/>
          </svg>
           <!-- v-show="highlightDesktopTrackerButton" class="selected" -->
          <svg width="22" height="21" v-show="isShowLiveTrackerUrl" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.98558 1.75C6.20225 1.75 3.125 4.82724 3.125 8.61058C3.125 13.6904 9.30128 18.5533 9.56415 18.7577C9.68784 18.8544 9.8367 18.9024 9.98557 18.9024C10.1344 18.9024 10.2833 18.8544 10.4068 18.7577C10.4845 18.6973 11.0787 18.2301 11.8773 17.4708C11.0104 16.7025 10.464 15.5807 10.464 14.3312C10.464 13.3628 10.7923 12.4711 11.3436 11.7612C10.9269 11.9415 10.4677 12.0415 9.98555 12.0415C8.09396 12.0415 6.55499 10.5025 6.55499 8.61093C6.55499 6.71933 8.09396 5.18036 9.98555 5.18036C11.8773 5.18036 13.4163 6.71914 13.4161 8.61093C13.4161 9.33881 13.1882 10.0145 12.8001 10.5703C13.36 10.2932 13.9907 10.1375 14.6578 10.1375C15.3405 10.1375 15.9852 10.3006 16.5549 10.5901C16.7404 9.9407 16.8469 9.27752 16.8469 8.61058C16.8469 4.82724 13.7691 1.75 9.98558 1.75ZM9.98555 10.6691C8.85076 10.6691 7.92719 9.74549 7.92719 8.61074C7.92719 7.47595 8.85081 6.55238 9.98555 6.55238C11.1203 6.55238 12.0439 7.476 12.0439 8.61074C12.0439 9.74553 11.1205 10.6691 9.98555 10.6691Z" fill="none"/>
            <path d="M14.658 11.186C13.8238 11.186 13.0238 11.5175 12.434 12.1073C11.8441 12.6972 11.5127 13.4972 11.5127 14.3313C11.5127 15.1655 11.8441 15.9655 12.434 16.5554C13.0238 17.1453 13.8239 17.4767 14.658 17.4767C15.4922 17.4767 16.2922 17.1453 16.8821 16.5554C17.4719 15.9656 17.8033 15.1656 17.8033 14.3313C17.8033 13.7792 17.658 13.2368 17.3819 12.7587C17.1059 12.2806 16.7088 11.8835 16.2307 11.6074C15.7526 11.3314 15.2101 11.186 14.658 11.186ZM15.961 15.7422L15.5566 15.1896L15.8914 14.1673L16.5384 13.9562L17.354 14.538C17.3211 14.9647 17.1871 15.3775 16.9631 15.7422H15.961ZM16.7091 12.5969L16.4036 13.5405L15.7543 13.7494L14.8826 13.1069V12.4329L15.6869 11.8488C16.0805 12.0112 16.4306 12.2635 16.7092 12.5857L16.7091 12.5969ZM13.629 11.8398L14.4333 12.4239V13.0979L13.5616 13.7292L12.9123 13.5203L12.6067 12.5857C12.8843 12.2603 13.2344 12.0047 13.6289 11.8398L13.629 11.8398ZM12.3641 15.7423C12.1401 15.3776 12.0061 14.9648 11.9732 14.5381L12.7752 13.9562L13.4222 14.1674L13.7593 15.1896L13.3549 15.7423H12.3641ZM14.0244 16.9488L13.7188 16.0074L14.1188 15.4548H15.1973L15.5972 16.0074L15.2916 16.9488H15.2915C14.8757 17.0535 14.4403 17.0535 14.0244 16.9488H14.0244Z" fill="none"/>
          </svg>
        </div>

        <FavStarButton 
          v-if="matchDetailScore.isFavMatch !== undefined  && false"
          class="last-nav-pill"
          :isFav="isFavMatch"
          :isInNavPill="true"
          :favDisabled="matchEnded && isLogin"
          @toggleFavThis="toggleFavMatch($event)"
        ></FavStarButton>
      </div>

      <div
        :class="{
          'match-detail__header-top__section': liveSteamVideo.isShowVideo || isTrackerSelected || !isMobile,
        }"
      >
      <!-- 'hide': !liveSteamVideo.isShowVideo -->
        <Transition name="minor-slide-from-top">
          <div
            class="relative match-detail__video-live__container"
            :class="{ 'w-100': isMobile, 'w-70': !isMobile  }"
            v-show="liveSteamVideo.isShowVideo"
          >
            <template v-if="!isLogin">
              <div class="w-100 match-detail__video-live"></div>
            </template>

            <template v-else-if="isLogin">
              <div id="OverlayAdInfo" v-if="!userViewedAd">Ad 1 of 1 · <span id="AdRemainTime">{{ adDurationDisplayText }}</span></div>
              <!-- AS-991 - with new design, remove live stream iconwhich overlayed on video -->
              <!-- <img
                class="match-detail__video-live-icon"
                src="../../../../../static/images/icons/icon_live_video.gif"
                v-else-if="userViewedAd"
              /> -->
              <!-- <video v-if="isMobile && liveSteamVideo.isPlayVideo" id="videoLiveRef" class="video-js vjs-default-skin w-100 match-detail__video-live" controls="true" autoplay playsinline ></video> -->
              <video
                ref="videoLiveRef"
                id="videoLiveRef"
                class="video video-js vjs-default-skin w-100 match-detail__video-live"
                controls
                playsinline
                v-if="matchDetailScore.liveStreamType !== youTube.text"
              ></video>
              
              <YouTubePlayer v-if="youTube.switchToYT" ref="youtubeRef" class="video video-js vjs-default-skin w-100 match-detail__video-live"  :ytEmbedLink="liveSteamVideo.selectedLiveStreamUrl" :playPauseVideo="youTube.playPause"></YouTubePlayer>
            </template>

            <!-- liveSteamVideo.isPlayVideo -->
            <div
              v-if="!isLogin && liveSteamVideo.isShowVideo"
              class="match-detail__video-live-overlay"
            >
              <div class="match-detail__video-live-overlay__title">
                {{ $t("MATCH_DETAIL_TO_SIGN_IN_VIEW_LIVE_VIDEO") }}
              </div>
              <div
                v-if="isMobile"
                class="match-detail__video-live-overlay__button-wrapper"
              >
                <router-link
                  :to="{
                    name: 'signup',
                    query: { callBackUrl: currentPathName },
                  }"
                >
                  <button class="btn w-100 mb-1rem bg-white">
                    {{ $t("SIGNUP") }}
                  </button></router-link
                >
                <router-link
                  :to="{
                    name: 'signin',
                    query: { callBackUrl: currentPathName },
                  }"
                  ><button class="btn btn-outline w-100">
                    {{ $t("LOGIN") }}
                  </button></router-link
                >
              </div>
              <div
                v-else
                class="match-detail__video-live-overlay__button-wrapper"
              >
                <button
                  class="btn w-100 mb-1rem bg-white"
                  @click="handleAuthentication('signUp')"
                >
                  {{ $t("SIGNUP") }}
                </button>
                <button
                  class="btn btn-outline w-100"
                  @click="handleAuthentication('signIn')"
                >
                  {{ $t("LOGIN") }}
                </button>
              </div>
            </div>
            <div class="match-detail__video-live__channel" v-if="!isTrackerSelected && liveSteamVideo.liveStreamType !== youTube.text">
              <!-- <div id="liveSteamVideoChannelId" class="nav-pill" :class="{'selected' :  liveSteamVideo.currentChannelSelected}" >
                                  <span class="mr-1rem">{{liveSteamVideo.currentChannel}}</span>
                                  <i class="svg-arrow__vertical "  :class="{'selected': liveSteamVideo.currentChannelSelected}">
                                      <svg width="13" height="8" viewBox="0 0 13 8" class="league-dropdown__svg" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z" fill="inherit"/>
                                      </svg>
                                  </i>
                              </div> -->
              <div
                class="nav-pill"
                @click="handleliveSteamChannel(o, index)"
                :class="{ selected: o.selected }"
                v-for="(o, index) in liveSteamVideo.liveStreamUrl"
                :key="index"
              >
                {{ $t("CHANNEL") }} {{ index + 1 }}
              </div>
            </div>
          </div>
        </Transition>

        <!-- 'w-70': !isMobile, -->
        <Transition name="minor-slide-from-top">
          <!-- 'hide': !isTrackerSelected -->
          <div
            v-show="isTrackerSelected"
            class="display-flex match-detail__tracker-commentary__wrapper"
            :class="{ 'w-100': isMobile }"
            ref="trackerContainer"
          >
            <div class="match-detail__tracker-video">
              <!-- <div class="match-detail__tracker-header">
                  {{$t('TRACKER')}}
              </div> -->
              <iframe  width="100%"  frameBorder="0" class="iframe" :src="matchDetailLiveTrackerUrl"></iframe>
            </div>
          </div>
        </Transition>

        <ScoreInfoMobile
          :data="matchDetailScore"
          :winRate="matchDetailWinRate"
          :detailOdds="matchDetailOdds"
          v-if="isMobile"
        ></ScoreInfoMobile>
        <!-- <div class="match-detail__wrapper" v-if="isMobile">
          <div class="flex-1 relative">
            <div class="match-detail__status">
              {{
                matchDetailScore.matchStatus &&
                matchDetailScore.matchStatus.includes(":")
                  ? matchDetailScore.matchTime
                  : matchDetailScore.matchStatus
              }}
            </div>
            <Navigation
              :id="matchDetailScore.homeTeamId"
              :category="'team'"
              :name="matchDetailScore.homeTeamName"
              class="match-detail__container"
            >
              <div class="match-detail__opponent">
                <Icon
                  :logoUrl="matchDetailScore.homeLogo"
                  class="match-detail__icon "
                ></Icon>
                <span>{{ matchDetailScore.homeTeamName }}</span>
                <img
                  class="ml-1rem"
                  src="../../../../../static/images/label/label_pen_win.png"
                  v-if="
                    matchDetailScore.homeTeamPenaltyScore >
                      matchDetailScore.awayTeamPenaltyScore
                  "
                />
              </div>
              <div class="display-flex">
                <div
                  class="match-detail__score"
                  :class="{
                    'match-detail__penatly-score':
                      matchDetailScore.homeTeamPenaltyScore,
                  }"
                >
                  {{ matchDetailScore.homeTeamScore }}
                </div>
                <div
                  class="match-detail__divider"
                  v-if="matchDetailScore.homeTeamPenaltyScore"
                ></div>
                <div
                  class="match-detail__score"
                  :class="{
                    'match-detail__penatly-score':
                      matchDetailScore.homeTeamPenaltyScore,
                  }"
                  v-if="matchDetailScore.homeTeamPenaltyScore"
                >
                  {{ matchDetailScore.homeTeamPenaltyScore }}
                </div>
              </div>
            </Navigation>
            <Navigation
              :id="matchDetailScore.awayTeamId"
              :category="'team'"
              :name="matchDetailScore.awayTeamName"
              class="match-detail__container"
            >
              <div class="match-detail__opponent">
                <Icon
                  :logoUrl="matchDetailScore.awayLogo"
                  class="match-detail__icon"
                ></Icon>
                <span>{{ matchDetailScore.awayTeamName }} </span>
                <img
                  class="ml-1rem"
                  src="../../../../../static/images/label/label_pen_win.png"
                  v-if="
                    matchDetailScore.awayTeamPenaltyScore >
                      matchDetailScore.homeTeamPenaltyScore
                  "
                />
              </div>
              <div class="display-flex">
                <div
                  class="match-detail__score"
                  :class="{
                    'match-detail__penatly-score':
                      matchDetailScore.homeTeamPenaltyScore,
                  }"
                >
                  {{ matchDetailScore.awayTeamScore }}
                </div>
                <div
                  class="match-detail__score"
                  :class="{
                    'match-detail__penatly-score':
                      matchDetailScore.homeTeamPenaltyScore,
                  }"
                  v-if="matchDetailScore.awayTeamPenaltyScore"
                >
                  {{ matchDetailScore.awayTeamPenaltyScore }}
                </div>
              </div>
            </Navigation>
          </div>
          <div
            class="home-match__icon-highlight match-detail__icon-highlight"
            :class="{ selected: matchDetailScore.containHighlight }"
            @click="handleHighlight($event)"
          ></div>
        </div> -->

        <div
          class=" display-flex-sb has-space mb-1rem"
          :class="{
            'match-detail__header-top__section-tab': liveSteamVideo.isShowVideo || isTrackerSelected,
          }"
        >
          <div id="matchDetailTabMenuWrapper" class="display-flex home-nav__wrapper">
            <router-link
              :to="{ name: `${o.name}` }"
              class="sub-nav__child"
              v-for="(o, index) in tabs"
              :key="index"
              @click="currentPathnameHandler(o)"
              :id="o.name"
            >
              <span class="sub-nav__child-text">{{ $t(o.name) }}</span>
              <div
                class="sub-nav__child-indicator"
                :class="{ 'nav-selected': o.name === currentPage }"
              ></div>
            </router-link>
          </div>
          <div v-if="!isMobile">
            <SocialMediaSharing></SocialMediaSharing>
          </div>
        </div>
      </div>

      <div
        :class="{
          'match-detail__header-bottom__section': liveSteamVideo.isShowVideo || isTrackerSelected,
        }"
      >
        <div class="match-detail__content-container">
          <router-view :matchDetailScore="matchDetailScore" v-if="isMobile" v-slot="{ Component }">
            <transition name="slide">
              <component :is="Component" />
            </transition>
          </router-view>
          <router-view :matchDetailScore="matchDetailScore" v-else />
        </div>
      </div>
    </div>
  </div>

  <div v-if="isShowCurrentVideo" class="display-flex-center video-wrapper">
    <div class="video-container">
      <div class="display-flex-fe">
        <div class="video-close" @click="closeCurrentVideo()"></div>
      </div>
      <video
        style="height:17rem"
        ref="currentVideoId"
        v-if="isAudioType"
        controls
        :src="currentVideoUrl"
      ></video>
      <iframe :src="currentVideoUrl" style="height:17rem" v-else></iframe>
    </div>
  </div>
  <Drawer ref="drawerComponent" :isShowMediaSharing="true"></Drawer>

  <!-- sign up info -->
  <ModalPopup ref="modalPopupSubmitRef">
    <div class="display-flex-align-center flex-col modal-confirmation__body">
      <div class="mb-15rem font-m tc">
        {{ $t("FAV_LOGIN_TIPS") }}
        <!-- {{ $t("MATCH_DETAIL_TO_SIGN_IN_VIEW_LIVE_VIDEO") }} -->
      </div>
      <div v-if="isMobile" class="w-100">
        <router-link
          :to="{ name: 'signup', query: { callBackUrl: currentPathName } }"
        >
          <button class="btn w-100 mb-1rem bg-white c-black">
            {{ $t("SIGNUP") }}
          </button></router-link
        >
        <router-link
          :to="{ name: 'signin', query: { callBackUrl: currentPathName } }"
          ><button class="btn btn-outline w-100">
            {{ $t("LOGIN") }}
          </button></router-link
        >
      </div>
      <div v-else class="w-100">
        <button
          class="btn w-100 mb-1rem bg-white"
          @click="handleAuthenticationForFav('signUp')"
        >
          {{ $t("SIGNUP") }}
        </button>
        <button
          class="btn btn-outline w-100"
          @click="handleAuthenticationForFav('signIn')"
        >
          {{ $t("LOGIN") }}
        </button>
      </div>
    </div>
  </ModalPopup>
  <PopUpSignIndex
    ref="modalPopupSignInRef"
    @handleCloseEvent="handleCloseEventSignInRef"
    @loginSucuessEvent="loginSucuessEvent($event)"
  ></PopUpSignIndex>
</template>

<script>
/** 
 * Match State
  0：未开赛
  1：一节
  2：二节
  3：三节
  4：四节
  5：1'OT
  6：2'OT
  7：3'OT
  50：中场
  -1：完场
  -2：待定
  -3：中断
  -4：取消
  -5：推迟
*/
// @ is an alias to /src
import MobileHeader from "@/components/mobile/Header.vue";
import ScoreInfo from "@/components/matchDetail/ScoreInfo.vue";
import ScoreInfoMobile from "@/components/matchDetail/ScoreInfoMobile.vue";
import SocialMediaSharing from "@/components/socialMediaSharing/SocialMediaSharing.vue";
import Drawer from "@/components/modal/Drawer.vue";
import ModalPopup from "@/components/modal/ModalPopup.vue";
import PopUpSignIndex from "@/components/user/PopUpSignInIndex.vue";
import FavStarButton from "@/components/ui/FavStarButton.vue";
import YouTubePlayer from "@/components/matchDetail/YouTubePlayer.vue";

import moment from "moment";
import config from "@/js/config.js";

import MobileSelect from "@/lib/mobile-select";
import Hls from "hls.js";
import videojs from "video.js";
// import DPlayer from 'dplayer';

import { mapGetters, mapMutations, mapActions } from "vuex";

let mobileSelectSubLeague;
const LIVE_VIDEO_ID = "videoLiveRef";
export default {
  name: "Home",
  components: {
    MobileHeader,
    ScoreInfo,
    ScoreInfoMobile,
    SocialMediaSharing,
    Drawer,
    ModalPopup,
    PopUpSignIndex,
    FavStarButton,
    YouTubePlayer,
  },
  data() {
    return {
      matchDetailScore: {},
      apiParamDateFormat: config.apiParamDateFormat,
      mobileHeaderTitle: "",//MATCH_DETAILS
      tabs: [
        {
          name: "BASKETBALL_INFO",
          path: "info",
          pathname: "info",
          value: 1,
          selected: true,
        },
        {
          name: "BASKETBALL_BOX_SCORE",
          path: "box-score",
          pathname: "box-score",
          value: 6,
          selected: false,
        },
        {
          name: "BASKETBALL_STATS",
          path: "stats",
          pathname: "stats",
          value: 2,
          selected: false,
        },
        // {
        //   name: "BASKETBALL_ANALYSIS",
        //   path: "analysis",
        //   pathname: "analysis",
        //   value: 3,
        //   selected: false,
        // },
        // {
        //   name: "BASKETBALL_LINEUPS",
        //   path: "lineups",
        //   pathname: "lineups",
        //   value: 4,
        //   selected: false,
        // },
        {
          name: "BASKETBALL_FEED",//TRACKER  COMMENTARY
          path: "tracker",
          pathname: "tracker",
          value: 5,
          selected: false,
        },
        {
          name: "BASKETBALL_H2H",
          path: "h2h",
          pathname: "h2h",
          value: 6,
          selected: false,
        },
      ],
      currentTabs: 10,

      isShowCurrentVideo: false,
      currentVideoUrl: "",
      isAudioType: false,

      video: {
        isShowVideoThunmnail: false,
      },
      thumbnailVideoList: [],
      interval: null,

      mobile: {
        isSelectSportType: false,
        currentSportType: {},
        isSelectMatch: false,
        isSelectedMatchChild: false,
      },
      isLoading: false,
      highlightDetailLink: {},
      isGetLiveSteamUrl: false,
      liveSteamVideo: {
        player: null,
        isShowVideo: false,
        isFirstTimeLoad: false,
        liveStreamUrl: [],
        currentChannel: 1,
        currentChannelSelected: false,
        isPlayVideo: false,
        selectedLiveStreamUrl: null,
        liveStreamType: null,
      },
      currentPathName: "",
      currentPage: "INFO",
      livestreamAdViewHitSessionLimit: false,
      adDuration: 0,
      adDurationDisplayText: "",
      userViewedAd: true,
      actualPlayedTime: 0,
      adVideo: null,
      advertisementId: null,
      switchNewVideoChannel: null,
      userSwitchedLanguage: false,
      isFavMatch: false,
      matchEnded: true,
      isTrackerSelected: false,
      highlightDesktopTrackerButton: false,
      isShowLiveTrackerUrl: null,
      matchDetailLiveTrackerUrl:'',
      modalPopupMessage: "",
      navPillLiveStreamStateClass: "disabled",
      navPillTrackerStateClass: "disabled",
      isMatchEndedOrCancelled: false,

      matchDetailWinRate: {},
      matchDetailOdds: {},

      youTube: {
        text: "youtube",
        playPause: true,
        switchToYT: false,
      },
    };
  },
  beforeUnmount() {
    clearInterval(this.interval);
    this.clearAdVideoListener();
    this.removeVisibilityChangeListener();
  },
  watch: {
    matchDetailScore: {
      deep: true,
      handler(newVal) {
        // this.intiHighlightDetailLink();
        if (newVal) {
          let routeParams = this.$route.params;
          let patt = /(\s|\/)/g;
          let newRouteParamsOpponent = newVal.homeTeamName.replace(patt, "-") + "-vs-" + newVal.awayTeamName.replace(patt, "-");
          if (routeParams.opponent !== newRouteParamsOpponent) {
            this.$router.replace(decodeURI(this.$route.fullPath).replace(routeParams.opponent, newRouteParamsOpponent));
          }
        }
      },
    },
    currentLocale: {
      deep: true,
      handler(newVal, oldVal) {
        let isGetNewData = true;
        this.init();
        this.userSwitchedLanguage = true;
        // if last language pre stream ad got problem, when switched to new one, need toggle
        this.livestreamAdViewHitSessionLimit = false;
      },
    },
    userSwitchCountryLang: {
        handler(newVal, oldVal) {
          location.reload();
        }
    },
    isLogin: {
      deep: true,
      handler(current, prev) {
        let isLogin = current;
        this.getMatchDetailScoreDataAsync();
        if (!isLogin) {
          this.removeVisibilityChangeListener();
          //if logout then close live video
          if (this.matchDetailScore.containLiveStream && Object.keys(this.matchDetailScore).length > 0 && this.liveSteamVideo.player) {
            this.pauseLiveVideo();
          } else {
            this.liveSteamVideo.isShowVideo = false;
          }
        } else {
          this.addVisibilityChangeListener();
          // if user switching to other account which haven't view enough ad today
          this.livestreamAdViewHitSessionLimit = false;
          // livestreamAdViewHitSessionLimit - here to stop ad call by switching language after done viewing ad
          if (!this.livestreamAdViewHitSessionLimit && !this.isStopTimmer && this.matchDetailScore.containLiveStream) {
            this.getLivestreamAdInfoData();
          }
        }
      },
    },
    $route: {
      immediate: true,
      handler(newVal) {
        if (
          newVal.name === "basketballStatsAnalysis" ||
          newVal.name === "basketballStatsHandicap" ||
          newVal.name === "basketballStatsGoals" ||
          newVal.name === "basketballStatsWDL"
        ) {
          this.currentPage = "BASKETBALL_ANALYSIS";// STATS
        } else this.currentPage = this.$route.name;
      },
    },
    manualRefreshMatchDetail: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.getMatchDetailScoreDataAsync();
        }
      },
    },
  },
  created() {},

  computed: {
    ...mapGetters([
      // 'matchDetailScore',
      "isMobile",
      "currentLocale",
      "currentSportType",
      "isStopTimmer",
      "isLogin",
      "userInfo",
      "manualRefreshMatchDetailScore",
      "manualRefreshMatchDetail",
      "userSwitchCountryLang"
    ]),
  },

  mounted() {
    this.init();
  },

  methods: {
    ...mapMutations([
      "MANUAL_REFRESH_MATCH_DETAIL_SCORE",
    ]),
    ...mapActions([
      // "getMatchDetailScore",
      "getBasketballMatchDetailScore",
      "getMatchDetailScoreWinRate",
      "getMatchDetailScoreLatestEuropeOdds",
      "updateMatchDetailScore",
      "getBasketballMatchDetaiHighlightList",
      "getLivestreamAdInfo",
      "postUpdateAdViewedTimes",
      "popUpSignInModuleEvent",
      "commonVerifyLogin",
      "isHasLive",
      "addFavMatch",
      "unFavMatch",
      "favLoginTipsChangeEvent",
      "getMatchDetailLiveTrackerUrl",
      "toastrFavChangeEvent",
    ]),
    stickyCurrentTabMenu(id) {
      document.getElementById("matchDetailTabMenuWrapper").scrollTo({
        top: 0,
        left: document.getElementById(id).offsetLeft,
        behavior: "smooth",
      });
    },
    currentPathnameHandler(o) {
      this.stickyCurrentTabMenu(o.name);
      this.currentPage = o.name;
    },
    init() {
      this.matchId = this.$route.params.matchId;
      this.currentPathName = window.location.pathname;
      //this.getMatchDetailScoreData();
      this.commonVerifyLogin();
      this.getMatchDetailScoreDataAsync();
      this.getMatchDetailScoreDataAsyncInterval();
      if (this.isLogin) {
        this.addVisibilityChangeListener();
      }
      this.stickyCurrentTabMenu(this.currentPage);
    },

    // react to resume / pause live stream if user switched away from the page / tab
    toggleTabHandler() {
      if (this.liveSteamVideo.isShowVideo) {
        if (document.visibilityState === "hidden") {
          this.pauseLiveVideo();
        } else {
          this.playLiveVideo();
        }
      }
    },

    addVisibilityChangeListener() {
      document.addEventListener("visibilitychange", this.toggleTabHandler);
    },

    removeVisibilityChangeListener() {
      document.removeEventListener("visibilitychange", this.toggleTabHandler);
    },

    // Part of the variables / conditions to determine whether to display live stream, tracker button (on both Web, WebApp)
    checkIfMatchEndedOrCancelled() {
      if (Object.keys(this.matchDetailScore).length > 0) {
        /**
         * -1 - Match ends
         * -10 - Canc.
         * -14 - Postp.
         */
        if ([-1, -10, -14].includes(this.matchDetailScore.matchState)) {
          this.isMatchEndedOrCancelled = true;
        } else {
          this.isMatchEndedOrCancelled = false;
        }
      }
    },
    
    updateLiveStreamStateClass() {
      this.checkIfMatchEndedOrCancelled(); // for both Web, WebApp
      
      // For Web only
      // live stream can be feed in "before match starts"
      if (this.matchDetailScore.containLiveStream) {
        this.navPillLiveStreamStateClass = "active";
      } else if (this.matchDetailScore.matchState > 0 && !this.matchDetailScore.containLiveStream) {
        // disable "live stream button" if live is N/A "during match"
        this.navPillLiveStreamStateClass = "disabled";
      }
    },

    /**
     * AS-991 (Phase 2) - 23032023 - By YIM
     * 1- Update Web nav pill state class
     * 2- Determine if to 'initLiveVideo' again within each interval (replace video src, channel url list, play/pause state)
     * 3- Get most updated tracker URL
     * 4- Update scores detail via dispatch 'updateMatchDetailScore'
     * 5- Auto toggle fav match button state, css class
     */
    updateLiveStreamTrackerScoreFavUI(matchDetailScore) {
      this.updateLiveStreamStateClass();
      // if tracker N/A at beginning and then available after that (while user still keep the same page open)
      this.getMatchDetailLiveTrackerUrlData();
      /**
       * initVideo again if previous live stream url different with latest one,
       * also if live stream N/A at beginning and then available after that (while user still keep the same page open)
       * 
       * Ignore if not 'isLogin' as live stream required to be logged in
       */
      if (matchDetailScore.liveStreamUrl !== null && this.liveSteamVideo.liveStreamUrl !== null && this.isLogin) {
        this.liveSteamVideo.isShowVideo = true;
        if (this.isTrackerSelected) {
          this.$refs.trackerContainer.classList.remove("relative");
        }
        /**
         * 'if' condition below only for interval, NOT for 1st time load
         * (as WebApp manual refresh banned to update live stream)
         */
        if (this.interval !== null) {
          // strip off "selected" property for comparison
          let tempOldUrlList = this.liveSteamVideo.liveStreamUrl.map(obj => this.getPureLiveStreamData(obj));
          let tempNewUrlList = matchDetailScore.liveStreamUrl.map(obj => this.getPureLiveStreamData(obj));
          let oldLiveStreamUrl = JSON.stringify(tempOldUrlList);
          let newLiveStreamUrl = JSON.stringify(tempNewUrlList);
          if (oldLiveStreamUrl !== newLiveStreamUrl) {
            this.initLiveVideo();
          }
          if (matchDetailScore.liveStreamType === this.youTube.text && !this.isTrackerSelected) {
            this.initLiveVideo();
          }
          if (matchDetailScore.liveStreamType !== this.youTube.text) {
            this.toggleYTPlayState(false);
            this.youTube.switchToYT = false;
          }
        } else {
          if (this.isLogin) {
            this.$refs.trackerContainer.classList.add("relative");
          }
          this.initLiveVideo();
        }
      } else if (!matchDetailScore.containLiveStream && this.interval !== null) {
        this.liveSteamVideo.isShowVideo = false;
        if (this.liveSteamVideo.player !== null) {
          this.pauseLiveVideo();
        }
        if (this.isTrackerSelected) {
          this.$refs.trackerContainer.classList.add("relative");
        }
        this.toggleYTPlayState(false);
      } else if (matchDetailScore.containLiveStream && this.interval !== null) {
        this.liveSteamVideo.isShowVideo = true;
        if (this.isTrackerSelected) {// isShowLiveTrackerUrl
          this.$refs.trackerContainer.classList.remove("relative");
        }
      }
      this.updateMatchDetailScore(matchDetailScore);
      this.passiveToggleFavMatchButton();
    },

    getPureLiveStreamData(obj) {
      let url = obj.url.split("?")[0]; // omit the query string of the url
      return {
        fromBg: obj.fromBg,
        name: obj.name,
        state: obj.state,
        type: obj.type,
        url: url
      };
    },

    // getMatchDetailScoreData() {
    //   let getData = this.getMatchDetailScore({
    //     matchId: this.matchId,
    //     timeZone: this.$tools.getCurrentTimeZone(),
    //   });

    //   this.interval = setInterval(() => {
    //     if (this.isStopTimmer) {
    //       clearInterval(this.interval);
    //     } else {
    //       this.getMatchDetailScore({
    //         matchId: this.matchId,
    //         timeZone: this.$tools.getCurrentTimeZone(),
    //       });
    //     }
    //   }, 3000);
    // },
    async getMatchDetailScoreDataAsync() {
      let params = {
        matchId: this.matchId,
        timeZone: this.$tools.getCurrentTimeZone(),
      };

      // const resultWinRate = await this.getMatchDetailScoreWinRate(params);
      const result = await this.getBasketballMatchDetailScore(params);
      const resultOdd = await this.getMatchDetailScoreLatestEuropeOdds(params);

      this.matchDetailScore = result.result;
      // this.matchDetailWinRate = resultWinRate.result;
      this.matchDetailOdds = resultOdd.result;
      if (resultOdd.result === null) {
        this.matchDetailOdds = {}
        this.matchDetailOdds.awayOdds = "-";
        this.matchDetailOdds.drawOdds = "-";
        this.matchDetailOdds.homeOdds = "-";
      }
     

      // this.matchDetailScore.matchState = 1;
      // this.matchDetailScore.containLiveStream = true;
      this.updateLiveStreamTrackerScoreFavUI(this.matchDetailScore);
      
      this.isHasLive(this.matchDetailScore.containLiveStream);

      this.intiHighlightDetailLink();
      if (!this.manualRefreshMatchDetailScore) {
        this.initLiveVideo();
      }
    },

     getMatchDetailScoreDataAsyncInterval() {
       this.interval = setInterval(() => {
        if (this.isStopTimmer) {
          clearInterval(this.interval);
        } else {
          this.getMatchDetailScoreData();
        }
      }, 6000);
    },
    async getMatchDetailScoreData() {
       let params = {
        matchId: this.matchId,
        timeZone: this.$tools.getCurrentTimeZone(),
      };
      const result = await this.getBasketballMatchDetailScore(params);

      this.matchDetailScore = result.result;
// this.matchDetailScore.matchState = 1;
// this.matchDetailScore.containLiveStream = true;
      this.updateLiveStreamTrackerScoreFavUI(this.matchDetailScore);
    },
    async getMatchDetailLiveTrackerUrlData(){
      // const result = await this.getMatchDetailLiveTrackerUrl({"matchId" : this.matchId})

      if (this.manualRefreshMatchDetailScore) {
        this.MANUAL_REFRESH_MATCH_DETAIL_SCORE();
      }
      /*  AS-1816 - YIM - Tracker feature is uncertainty at the moment, hence disabled
      if (result.result === null){
        this.matchDetailLiveTrackerUrl = "";
        this.isShowLiveTrackerUrl = false;
        this.highlightDesktopTrackerButton = false;
        this.isTrackerSelected = false;
        this.navPillTrackerStateClass = "disabled";
      } else {
        // only update the tracker URL if got new one (better UX)
        if (this.matchDetailLiveTrackerUrl !== result.result.url) {
          this.matchDetailLiveTrackerUrl = result.result.url;
        }
        // this.matchDetailLiveTrackerUrl = "https://www.youtube.com/embed/KGuLrrW3Txg";
        this.isShowLiveTrackerUrl = true;
        this.navPillTrackerStateClass = this.isTrackerSelected ? "active" : "";
        if (!this.matchDetailScore.containLiveStream) {
          // this.highlightDesktopTrackerButton = true;
          // this.isTrackerSelected = true;
        }
      }
      */
    },
    passiveToggleFavMatchButton() {
      this.isFavMatch = !!this.matchDetailScore.isFavMatch;
      this.matchEnded = this.matchDetailScore.matchState < 0 ? true : false;
      //config.matchEndedStatus.includes(this.matchDetailScore.matchStatus);
      if (this.matchEnded) {
        this.isFavMatch = false;
      }
    },
    /**
     * AS-1373 - 19122022 - by YIM
     * 1) Show 3 ad video per 24 hours (from time after done wacthing 1st ad)
     * 2) Not allow to skip via switching channel / language
     * 3) Only show 1 ad video per session if done watching the ad video
     *    (will show again if refresh page and haven't hit daily ad view limit)
     *    - if switching channel / language within the shoudn't show ad video again
     * 4) Not allow fast forward ad
     */
    async getLivestreamAdInfoData() {
      /**
       * 20/02/2023 by YIM - Pre-stream Video Ad - disabled for PROD as business side requested
      */
      // this.$nextTick(() => {
      //   this.adVideo = document.getElementById(LIVE_VIDEO_ID);
      //   this.clearAdVideoListener();
      // });
      // const result = await this.getLivestreamAdInfo();
      // if (result.result !== null) {
      //   this.userViewedAd = false;
      //   this.$nextTick(() => {
      //     this.adVideo = document.getElementById(LIVE_VIDEO_ID);
      //     this.clearAdVideoListener();
      //     this.adVideo.src = result.result.videoUrl;
      //     this.advertisementId = result.result.advertisementId;
      //     /**
      //      * tweak for ios quirk (display play icon on video control even if video is actually 
      //      * paused on 1st load, unable to display remaining ad duration properly while playing)
      //      */
      //     this.playLiveVideo();
      //     this.pauseLiveVideo();

      //     this.adVideo.addEventListener("error", this.adVideoErrorListener);
      //     this.adVideo.addEventListener("loadedmetadata",this.adVideoLoadedListener);
      //     this.adVideo.addEventListener("timeupdate", this.adVideoTimeUpdateListener);
      //     this.adVideo.addEventListener("ended", this.adVideoEndedListener);
      //     // revert to previous currentTime if user fast forward the ads
      //     this.adVideo.addEventListener("seeking", this.adVideoSeekingListener);
      //   });
      // } else {
        // No Ad available
        this.$nextTick(() => {
          this.adVideo = document.getElementById(LIVE_VIDEO_ID);
          this.showLivestreamAfterAds();
        });
      // }
    },

    // if ad video got problem, skip it
    adVideoErrorListener() {
      // console.error("Error: ",this.adVideo.error)
      this.showLivestreamAfterAds();
    },

    adVideoLoadedListener() {
      this.adDuration = this.adVideo.duration;
      this.displaySecondsInText(this.adVideo.currentTime);
    },

    adVideoTimeUpdateListener() {
      this.displaySecondsInText(this.adVideo.currentTime);
      if (!this.adVideo.seeking) {
        this.actualPlayedTime = this.adVideo.currentTime;
      }
    },

    adVideoEndedListener() {
      if (this.isLogin) {
        this.postUpdateAdViewedTimesData();
      }
    },

    adVideoSeekingListener() {
      // guard against infinite recursion:
      // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
      let delta = this.adVideo.currentTime - this.actualPlayedTime;
      if (Math.abs(delta) > 0.01) {
        this.adVideo.currentTime = this.actualPlayedTime;
      }
    },

    clearAdVideoListener() {
      if (this.adVideo) {
        this.adVideo.removeEventListener("error", this.adVideoErrorListener);
        this.adVideo.removeEventListener("loadedmetadata", this.adVideoLoadedListener);
        this.adVideo.removeEventListener("timeupdate", this.adVideoTimeUpdateListener);
        this.adVideo.removeEventListener("ended", this.adVideoEndedListener);
        this.adVideo.removeEventListener("seeking", this.adVideoSeekingListener);
      }
    },

    displaySecondsInText(videoCurrentTime) {
      let remaining = (this.adDuration - videoCurrentTime).toFixed(0);
      if (parseInt(remaining) >= 0 && remaining != "-0") {
        this.adDurationDisplayText = "00:" + remaining.padStart(2, "0");
      }
    },

    async postUpdateAdViewedTimesData() {
      let params = {
        advertisementId: this.advertisementId,
      };
      const result = await this.postUpdateAdViewedTimes(params);
      this.showLivestreamAfterAds();
    },

    showLivestreamAfterAds() {
      this.userViewedAd = true;
      this.livestreamAdViewHitSessionLimit = true;
      this.initLiveVideo();
      this.clearAdVideoListener();
    },

    initLiveVideo() {
      // this.matchDetailScore.containLiveStream = true;
      let that = this;
      if (this.matchDetailScore.containLiveStream) {
        // if (!this.liveSteamVideo.isFirstTimeLoad) {
          this.liveSteamVideo.liveStreamUrl = this.matchDetailScore.liveStreamUrl;
          this.liveSteamVideo.liveStreamType = this.matchDetailScore.liveStreamType;
        let params = [
          {
            name: "chanel1",
            url:
              "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8",
          },
          {
            name: "chanel2",
            url: "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8",
          },
        ];
        // this.liveSteamVideo.liveStreamUrl = params
        // let liveStreamUrlSelected = "";
        //let liveStreamUrl = "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8"
        this.liveSteamVideo.isShowVideo = true;
        if (this.liveSteamVideo.liveStreamUrl) {
          this.$nextTick(() => {
            if (this.userSwitchedLanguage) {
              this.switchNewVideoChannel = null;
            }
            this.liveSteamVideo.liveStreamUrl.forEach((x, index) => {
              //desktop version default choose the first channel
              x.selected = false;
              if ((index === 0 && this.switchNewVideoChannel == null) || this.switchNewVideoChannel == index) {
                this.liveSteamVideo.selectedLiveStreamUrl = x.url; //liveStreamUrlSelected
                x.selected = true;
              }
            });
            
            if (this.liveSteamVideo.liveStreamType === this.youTube.text) {
              this.toggleYTPlayState(true);
              this.youTube.switchToYT = true;
              return;
            }

            let video = document.getElementById(LIVE_VIDEO_ID);
            this.liveSteamVideo.player = video;
            let videoSrc = this.liveSteamVideo.selectedLiveStreamUrl;
            
            if (video.canPlayType("application/vnd.apple.mpegurl")) {
                video.src = videoSrc;
              } else if (Hls.isSupported()) {
                if (this.liveSteamVideo.hls) {
                  this.liveSteamVideo.hls.destroy();
                  clearInterval(this.liveSteamVideo.hls.bufferTimer);
                  this.liveSteamVideo.hls = null;
                }
                var hls = new Hls();
                hls.loadSource(videoSrc);
                hls.attachMedia(video);
                this.liveSteamVideo.hls = hls;
              }

              if (this.isLogin && !this.isTrackerSelected) {
                // // remain paused if viewer paused it and stay on the page while new video coming in
                // if (!this.liveSteamVideo.isPlayVideo && this.interval !== null) {
                //   this.pauseLiveVideo();
                // } else {
                  this.playLiveVideo();
                // }
              } else {
                this.pauseLiveVideo();
                // this.liveSteamVideo.isPlayVideo = false;
                // this.liveSteamVideo.player.pause();
              }

              this.liveSteamVideo.player.onplay = function() {
                let latestFrame = that.liveSteamVideo.player.duration;
                let url;
                if (latestFrame === Infinity) {
                  that.liveSteamVideo.liveStreamUrl.forEach((x) => {
                    if (x.selected) {
                      url = x.url;
                    }
                  });
                  that.liveSteamVideo.player.src = url;
                  that.playLiveVideo();// that.liveSteamVideo.player.play();
                } else {
                  that.liveSteamVideo.player.currentTime = latestFrame - 1;
                }
              };
              // var url = liveStreamUrl;
              // let videoId  = "videoLiveRef";
              // var video = document.getElementById(videoId);

              // if (!video) {
              //     videoId = "videoLiveRef_html5_api";
              //     video = document.getElementById(videoId);

              //     video.className = "video-js vjs-default-skin w-100 match-detail__video-live";
              // }

              // // var source = document.createElement('source');
              // // source.setAttribute('src', url);
              // // source.setAttribute('type', 'application/x-mpegURL');
              // // video.appendChild(source);

              // // this.liveSteamVideo.player = videojs(videoId,
              // // {
              // //     controls: true,
              // //     liveui: true
              // // });
              // let options = {
              //     controls: true,
              //     liveui: true,
              //     sources: [
              //         {
              //             src: liveStreamUrl,
              //             type: 'application/x-mpegURL',
              //         },
              //         {
              //             src: "https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8",
              //              type: 'application/x-mpegURL',
              //         }
              //     ]
              // }

              // this.liveSteamVideo.player = videojs(this.$refs.videoLiveRef, options, function onPlayerReady() {

              //     console.log('onPlayerReady', this);
              // })

              // if (this.isLogin) {
              //     this.playLiveVideo();
              // }
              // else {
              //     this.liveSteamVideo.isPlayVideo = false;
              //     this.liveSteamVideo.player.pause()
              // }
          });
        }
      } else {
        this.liveSteamVideo.isShowVideo = false;
        // clear the video src, etc. if admin removed the source / live stream ended / N/A
        this.liveStreamDeactivedAction();
        if (this.liveSteamVideo.player !== null) {
          this.pauseLiveVideo();
        }
      }
    },

    playLiveVideo() {
      this.liveSteamVideo.isPlayVideo = true;
      if (!isNaN(this.liveSteamVideo.player?.duration) && this.liveSteamVideo.player?.duration !== Infinity && this.liveSteamVideo.liveStreamType !== this.youTube.text) {
        this.liveSteamVideo.player.currentTime =
          this.liveSteamVideo.player.duration - 1;
      }

      if (this.liveSteamVideo.liveStreamType !== this.youTube.text)
        this.liveSteamVideo.player.play();
      else
        this.toggleYTPlayState(true);
    },

    pauseLiveVideo() {
      this.liveSteamVideo.isPlayVideo = false;
      if (this.liveSteamVideo.liveStreamType !== this.youTube.text)
        this.liveSteamVideo.player.pause();
      else
        this.toggleYTPlayState(false);
    },

    toggleYTPlayState(bool) {
      this.youTube.playPause = bool ? true : false;
    },

    handleliveSteamChannel(o, index) {
      let src = "";
      //reset select channel options
      this.liveSteamVideo.liveStreamUrl.forEach((x) => {
        x.selected = false;
      });
      o.selected = true;
      // show ad video when switching channel
      this.userViewedAd = false;
      this.userSwitchedLanguage = false;
      this.getLivestreamAdInfoData();
      
      // let video = document.getElementById(LIVE_VIDEO_ID);
      // video.src = o.url;
      // if (this.livestreamAdViewHitSessionLimit) {
      //   src = o.url;

      //   if (this.liveSteamVideo.hls) {
      //     this.liveSteamVideo.hls.destroy();
      //     clearInterval(this.liveSteamVideo.hls.bufferTimer);
      //     this.liveSteamVideo.hls = null;

      //     var hls = new Hls();
      //     hls.loadSource(src);
      //     hls.attachMedia(this.liveSteamVideo.player);

      //     this.liveSteamVideo.hls = hls;
      //   } else {
      //     this.liveSteamVideo.player.src = src;
      //   }

      //   this.playLiveVideo();
      // } else {
        this.switchNewVideoChannel = index;//o.url;
      // }
    },
    handleAuthentication(type) {
      this.popUpSignInModuleEvent(type);
      this.$refs.modalPopupSignInRef.handleOpen();
      this.$refs.modalPopupSubmitRef.handleClose();
    },
    loginSucuessEvent(data) {
      this.$refs.modalPopupSubmitRef.handleClose();
      this.$refs.modalPopupSignInRef.handleClose();
      //this.playLiveVideo();
    },

    handleAuthenticationForFav(type) {
      this.popUpSignInModuleEvent(type);
      this.$refs.modalPopupSubmitRef.handleClose(); 
    },

    async getMatchDetailHighLightListData() {
      let params = {
        matchId: this.matchId, //2961357//
        //matchId : 2961357
      };
      const result = await this.getBasketballMatchDetaiHighlightList(params);
      if (result.result === null) {
        this.video.isShowVideoThunmnail = false;
      } else {
        this.video.isShowVideoThunmnail = true;
        for (let i = 0; i < result.result.length; i++) {
          result.result[i].selected = false;
        }
        this.thumbnailVideoList = result.result;
        for (let i = 0; i < this.thumbnailVideoList.length; i++) {
          //  if (this.$tools.isAudioType(this.thumbnailVideoList[i].videoUrl)) {
          if (/\.(mp3|mp4)$/i.test(this.thumbnailVideoList[i].videoUrl)) {
            this.thumbnailVideoList[i].isAudioType = true;
          } else {
            this.thumbnailVideoList[i].isAudioType = false;
          }
        }

        if (!this.isMobile) {
          this.showCurrentVideo(this.thumbnailVideoList[0]);
        }
      }
    },
    intiHighlightDetailLink() {
      let routeParams = this.$route.params;
      let matchDate = moment(this.matchDetailScore.matchTime).format(
        this.apiParamDateFormat
      );

      this.highlightDetailLink = {
        path: `/${this.currentLocale}/${this.currentSportType}/highlights/${routeParams.country}/${routeParams.club}/${routeParams.opponent}/${routeParams.matchId}`,
        query: { date: matchDate },
      };
    },
    handleHighlight(e) {
      if (this.matchDetailScore.containHighlight) {
        // e.preventDefault();
        // e.stopPropagation();
        let routeParams = this.$route.params;

        let matchDate = moment(this.matchDetailScore.matchTime).format(
          this.apiParamDateFormat
        );

        this.$router.push({
          path: `/${this.currentLocale}/${this.currentSportType}/highlights/${routeParams.country}/${routeParams.club}/${routeParams.opponent}/${routeParams.matchId}`,
          query: { date: matchDate },
        });
        return false;
      }
    },
    showCurrentVideo(o) {
      if (this.isMobile) {
        this.isShowCurrentVideo = true;

        this.$nextTick(() => {
          //this.$refs.currentVideoId.play()
        });
      } else {
        this.isShowCurrentVideo = false;
      }
      this.thumbnailVideoList.forEach((x) => {
        x.selected = false;
      });
      if (o) {
        if (o.isAudioType) {
          this.isAudioType = true;
        } else {
          this.isAudioType = false;
        }
        this.currentVideoUrl = o.videoUrl;

        o.selected = true;
      }
    },

    closeCurrentVideo() {
      this.isShowCurrentVideo = false;
    },
    async handleMore() {
      if (this.$tools.mobileCheck()) {
        let leagueName = this.matchDetailScore.leagueName;
        if (leagueName === null) {
          leagueName = this.matchDetailScore.country;
        }
        const shareData = {
          title:
            "Get latest sports scoring, statistics, historical data and news update on Asiasport.com",
          text: `${leagueName}: ${this.matchDetailScore.homeTeamName} ${this.matchDetailScore.homeTeamScore} - ${this.matchDetailScore.awayTeamScore} ${this.matchDetailScore.awayTeamName}`,
          url: window.location.href,
        };
        try {
          await navigator.share(shareData);
        } catch (err) {}
      } else {
        this.$refs.drawerComponent.showDrawer();
      }
    },
    async toggleFavMatch(data) {
      if (this.isLogin && !this.matchEnded) {
        let params = {
          matchId: this.matchId,
        };
        let paramsToastr = {
            isOpen: true,
            msg: this.$i18n.t("MATCH"),
            isAddedFav: false,
        };
        if (this.isFavMatch) {
          const result = await this.unFavMatch(params);
          if (result.message === "Success") {
            this.isFavMatch = false;

            paramsToastr.isAddedFav = false;
            this.toastrFavChangeEvent(paramsToastr);
          }
        } else {
          const result = await this.addFavMatch(params);
          if (result.message === "Success") {
            this.isFavMatch = true;

            paramsToastr.isAddedFav = true;
            this.toastrFavChangeEvent(paramsToastr);
          }
        }
      } else if (!this.Login && !this.isMobile) {
        this.favLoginTipsChangeEvent({isOpen: true, triggerOnParent: true, event: data, callBackUrl: this.currentPathName,}) 
      } else if (!this.isLogin && this.isMobile) {
        this.$refs.modalPopupSubmitRef.handleOpen();
      }
    },
    mouseoverTrackerButton() {
      this.highlightDesktopTrackerButton = true;
    },
    mouseoutTrackerButton() {
      if (!this.isTrackerSelected) {
        this.highlightDesktopTrackerButton = false;
      }
    },
    // AS-991
    toggleTracker() {
      if (!this.isShowLiveTrackerUrl) {
        return;
      }

      this.isTrackerSelected = !this.isTrackerSelected;
      if (this.isTrackerSelected) {
        this.navPillTrackerStateClass = "active";
        // if (this.matchDetailScore.containLiveStream) {
          this.liveStreamDeactivedAction();
        // }
      } else {
        this.navPillTrackerStateClass = "";
        // if (this.matchDetailScore.containLiveStream && this.liveSteamVideo.isShowVideo) {
          this.liveStreamActivedAction();
        // }
      }
    },
    liveStreamActivedAction() {
      if (this.matchDetailScore.containLiveStream) {
        this.liveSteamVideo.isShowVideo = true;
        this.navPillLiveStreamStateClass = "active";
        // this.liveSteamVideo.player.play();
        if (!this.isTrackerSelected && this.isLogin) {
          this.playLiveVideo();
        }
      }
      
      // this.liveSteamVideo.isShowVideo || !this.matchDetailScore.containLiveStream
      if (this.matchDetailScore.containLiveStream) {
        this.$refs.trackerContainer.classList.remove("relative");
      } else {
        this.$refs.trackerContainer.classList.add("relative");
      }
    },
    liveStreamDeactivedAction() {
      if (this.matchDetailScore.containLiveStream) {
        this.navPillLiveStreamStateClass = "active";// "" - live stream nav pill needs to be always "active" if contain live stream
        if (this.isLogin) {
          this.pauseLiveVideo();// this.liveSteamVideo.player.pause();
        }

        // !isLogin && liveSteamVideo.isShowVideo
      }
      
      // !this.liveSteamVideo.isShowVideo || !this.matchDetailScore.containLiveStream
      if (this.matchDetailScore.containLiveStream) {
        this.$refs.trackerContainer.classList.remove("relative");
      } else {
        this.$refs.trackerContainer.classList.add("relative");
      }
    },
    toggleLiveStream() {
      if (this.matchDetailScore.containLiveStream) {
        if (this.matchDetailLiveTrackerUrl) {
          this.navPillTrackerStateClass = "";
        }
        if (this.isTrackerSelected) {
          this.isTrackerSelected = !this.isTrackerSelected;
          this.liveSteamVideo.isShowVideo = true;
          this.liveStreamActivedAction();
        } else {
          // this.liveSteamVideo.isShowVideo = !this.liveSteamVideo.isShowVideo;
          // if (this.liveSteamVideo.isShowVideo) {
          //   this.liveStreamActivedAction();
          // } else {
          //   this.liveStreamDeactivedAction();
          // }
        }
      }
    },
  },
};
</script>
<style>
video#videoLiveRef::-webkit-media-controls-timeline,
video#videoLiveRef::-webkit-media-controls-current-time-display,
video#videoLiveRef::-webkit-media-controls-time-remaining-display {
  display: none;
}
.home-nav__wrapper {
  overflow: hidden;
}
.match-detail__content-container {
  min-height: 50vh;
}
/* .match-detail__header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
} */
.match-detail__header-top__section {
  position: relative;
}
.match-detail__header-info {
  text-align: center;
  margin-bottom: 1rem;
}

.match-detail__header-info__match {
  font-size: 0.875rem;
}
.match-detail__header-info__country {
  font-size: 0.563rem;
}
.match-detail__header-info__date {
  color: var(--color-grey-light);
  font-size: 0.688rem;
}
.match-detail__header-info__opponent {
  margin-bottom: 0.5rem;
}

.match-detail__header-info__home-name {
  width: 20rem;/*12rem*/
  text-align: right;
  overflow: hidden;
}
.match-detail__header-info__away-name {
  width: 20rem;/*12rem*/
  overflow: hidden;
}
.match-detail__header-info__penatly {
  border-top: 0.1rem solid #35454d;
  margin-top: 0.6rem;
  padding-top: 0.5rem;
}
/* .match-detail__header-info__penatly-title {
  font-size: 0.7rem;
  color: var(--color-grey-light);
  margin-bottom: 1rem;
} */

.match-detail__header-info__penatly-label {
  top: 0.6rem;

  position: absolute;
  width: 2.3rem;
}
.match-detail__header-info__penatly-label.left {
  right: 3.4rem;
}
.match-detail__header-info__penatly-label.right {
  left: 3.4rem;
}
.match-detail__header-info__score {
  width: 2rem;
  text-align: center;
}
.desktop-match-detail__thumbnail-wrapper {
  display: flex;
  flex-direction: row;
  height: 28rem;
  margin-bottom: 2rem;
}
.desktop-match-detail__thumbnail-overview {
  width: 90%;
  position: relative;
  margin-right: 2rem;
}
.desktop-match-detail__thumbnail-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.match-detail__status {
  font-size: 0.625rem;
  line-height: 1rem;
  color: #93a9b4;
}
.desktop-match-detail__thumbnail-overview img {
  width: 100%;
  height: 100%;
}
.desktop-match-detail__thumbnail-section__wrapper {
  /* width: 30%; */
  overflow-y: auto;
  cursor: pointer;
}
.desktop-match-detail__thumbnail-section {
  position: relative;
  margin-right: 0.5rem;
  height: 8rem;
  width: 14.7rem;
}
.desktop-match-detail__thumbnail-section img {
  width: 100%;
  height: 100%;
}
.desktop-match-detail__thumbnail-section__container {
  display: flex;
  padding: 0.5rem 0;
  border-bottom: 0.1rem solid #35454d;
  margin-right: 1.5rem;
}
.desktop-match-detail__thumbnail-section__container:first-child {
  padding-top: 0;
}
.desktop-match-detail__thumbnail-section__desc-wrapper {
  width: calc(100% - 6.7rem);
}
.desktop-match-detail__thumbnail-section__desc-duration {
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
}
.desktop-match-detail__thumbnail-section__desc-title {
  font-size: 0.88rem;
  color: #2e8fef;
  margin-bottom: 0.4rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  margin-bottom: 0.2rem;
  min-height: 2.2rem;
  text-align: left;
}
.desktop-match-detail__thumbnail-section__desc-tool {
  font-size: 0.7rem;
}

.match-detail__thumbnail-wrapper {
}
.match-detail__thumbnail-overview {
  position: relative;
  height: 13rem;
  overflow: hidden;
}
.match-detail__thumbnail-overview img {
  width: 100%;
  height: 100%;
}
.match-detail__thumbnail-section__overlay {
  position: absolute;
  background-color: rgb(0 0 0 / 22%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.match-detail__thumbnail-section__overlay.selected {
  border: 2px solid #2e8fef;
}
.match-detail__thumbnail-section__play-icon {
  width: 1rem;
  height: 1rem;
  background: url("../../../../../static/images/icons/icon_play.png") center/cover
    no-repeat;
}
.match-detail__thumbnail-section__play-desc {
  font-size: 0.7rem;
  margin-top: 0.2rem;
}
.match-detail__thumbnail-section__wrapper {
  display: flex;
  overflow-y: auto;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
}
.match-detail__thumbnail-section__container {
  min-width: 36%;
  padding: 0.5rem 0.5rem 0 0;
}
.match-detail__thumbnail-section {
  position: relative;
  padding-top: 70%;
  overflow: hidden;
}
.match-detail__thumbnail-section img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.match-detail__wrapper {
  margin: 0 0 1.5rem 1rem;
  position: relative;
  display: flex;
  align-items: center;
}
.match-detail__icon-highlight {
  margin: 0 0.8rem 0.8rem 0.8rem;
}
.match-detail__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  font-size: 0.85rem;
}
.match-detail__divider {
  width: 0.12rem;
  background-color: var(--color-grey-40);
  height: 4.2rem;
  position: absolute;
  right: 1.7rem;
  top: 1.2rem;
}
.match-detail__opponent {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cfd9de;
}
.match-detail__opponent:hover {
  color: var(--color-hover);
}
.match-detail__icon {
  margin: 0.2rem;
  fill: white;
}
.match-detail__score {
  color: white;
}

.match-filter {
  display: flex;
  margin: 1rem 0;
}
.match-filter__child {
  color: white;
  border: 1px solid white;
  border-radius: 1rem;
  padding: 0.4rem 1.1rem;
  margin-right: 1rem;
  cursor: pointer;
  font-size: 0.9rem;
}
.match-detail__penatly-score {
  width: 1.8rem !important;
  text-align: center;
}
.match-detail__video-live-icon {
  width: 1.5rem;
  height: 1.5rem;
  right: 1rem;
  position: absolute;
  z-index: 1;
  top: 1rem;
}
.match-detail__video-live__container {
  margin: 0 auto 1rem;
}
.match-detail__video-live-overlay {
  background-color: #34343494;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  box-sizing: border-box;
}
.match-detail__video-live-overlay__title {
  margin-bottom: 2rem;
  text-align: center;
}
.match-detail__video-live-overlay__button-wrapper {
  width: 20rem;
}
/* .video-js .vjs-big-play-button{
        display: none;
    } */
.match-detail__video-live {
  height: 480px;/*680px*/
}
.match-detail__video-live__channel {
  display: flex;
  margin-top: 1rem;
  overflow-x: auto;
}
.match-detail__video-live__channel-btn {
  border: 1px solid white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
}
.nav-selected {
  display: block !important;
}
#OverlayAdInfo {
  z-index: 1;
  position: absolute;
  bottom: 6rem;
  left: 1rem;
  font-size: 0.688rem;
}
.match-detail__tracker-commentary__wrapper{
  flex-direction: column;
  height: 55vw;
}
.match-detail__tracker-video{
  width: 70%;/*100%*/
  height: 480px;/*50vw   55vw*/
}
.match-detail__tracker-header{
  padding: 0 1rem 1rem 1rem;
  /* margin: 0 1rem; */
  text-align: left;
  color: var(--color-grey-light);
  font-size: 0.8rem;
}
.match-detail__tracker-video .iframe{
  height:100%
}
@media (min-width: 1000px) {
  .match-detail__tracker-commentary__wrapper{
    flex-direction: row;
    width: 100%;/*47.25rem*/
    height:480px;/*37rem  28rem*/
    justify-content: center;
    margin: 0 auto 1rem;
    position: absolute;
    top: 0;
    /* padding-bottom: 3rem; */
    background: #000105;
    z-index: 1;
  }
  .match-detail__info-timeline__middle {
    flex: 5.6 !important;
  }
}
@media (max-width: 768px) {
  .home-nav__wrapper {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    scroll-behavior: smooth;
  }
  .home-nav__wrapper::-webkit-scrollbar {
    display: none;
  }
  .match-detail__header-top__section {
    position: absolute;
    width: 100%;
    z-index: 3;
    background-color: var(--bg-theme);
    top: 3rem;
  }
  .match-detail__header-bottom__section {
    padding-top: calc(56vw + 180px);/*calc(56vw + 203px); 175px*/
  }
  .match-detail__video-live {
    height: 55vw;
  }
  .match-detail__header-top__section-tab {
    margin-bottom: 0;
  }
  .match-detail__video-live__channel {
    margin-left: 1rem;
    overflow-x: auto;
  }
  .match-detail__tracker-commentary__wrapper {
    position: absolute;
    top: 0;
    padding-bottom: 1rem;
    background: #000105;
    z-index: 1;
    /* margin-bottom: 1.5rem; */
  }
  .match-detail__tracker-video{
    width: 100%;
    height: 55vw;
  }

  /* Temporary - during revert Tracker back into Tracker tab */
  /* if no live stream then adjust */
  .match-detail__content-container {
    /* margin-top: 0; */
    /*9.8rem*/
  }
  /* if got live stream then contra */
  /* .match-detail__header-bottom__section .match-detail__content-container {
    margin-top: 0;
  } */
}
</style>
